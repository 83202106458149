<template>
    <div class="rounded-5 w-100 my-5 d-flex gap-5 flex-column">
        <div v-if="!loading" class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Select Modules
            </h1>

            <div class="d-flex flex-wrap p-4" style="gap: 0.5em">
                <ClinicianProfileFunctionCard
                    v-for="item in moduleList"
                    :key="item.id"
                    :profileFunction="item"
                    @onToggle="handleModuleToggle($event)"
                />
            </div>

            <span v-if="modulesErr" class="px-4 my-4" style="color: red; font-weight: bold">{{ modulesErr }}</span>

            <div class="d-flex justify-content-center">
                <hr style="width: 95%" />
            </div>

            <div class="p-4 d-flex justify-content-between flex-column">
                <div>
                    <label for="navigation_enabled" class="app-launcher-label">Enable Navigation</label>
                    <Toggle
                        id="navigation_enabled"
                        class="toggle-switch"
                        labelClass="switch"
                        spanClass="slider"
                        v-model="navigation_enabled"
                        @input="handleNavigationToggle($event)"
                    />
                </div>
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>

<script>
    import ClinicianProfileFunctionCard from '@/components/company/member-profiles/ClinicianProfileFunctionCard.vue';
    import Loading from '@/components/general/loading/loading.vue';

    export default {
        name: 'SelectModules',
        props: {
            modules: {
                type: Array,
                default: () => [],
            },
            navigationEnabled: {
                type: Boolean,
                default: true,
            },
            pageErrors: {
                type: Object,
                default: () => ({}),
            },
        },
        components: {
            ClinicianProfileFunctionCard,
            Loading,
        },
        data() {
            return {
                loading: false,
                moduleList: [],
                navigation_enabled: true,
                modulesErr: null,
            };
        },
        methods: {
            handleModuleToggle(event) {
                this.moduleList = this.moduleList.map((el) => {
                    if (event.profileFunction.id === el.id) {
                        el.active = event.value ? 1 : 0;
                    }
                    return el;
                });

                this.modulesErr = null;
                this.$emit('captureValues', { modules: this.moduleList });
            },
            handleNavigationToggle(event) {
                this.$emit('captureValues', { navigationEnabled: event });
            },
        },
        created() {
            this.moduleList = [...this.modules];
            this.navigation_enabled = this.navigationEnabled;
        },
        watch: {
            pageErrors: {
                deep: true,
                async handler(val) {
                    if (val.modules) {
                        this.modulesErr = val.modules;
                    }
                },
            },
        },
    };
</script>
