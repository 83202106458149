<template>
    <div class="configure-quick-links">
        <NoButtonModal :open="open" id="the_new_client_dropdown_modal">
            <div class="modal-list-third-party-app">
                <div class="section-header d-flex justify-content-between align-items-center ps-4 pe-2 py-1">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="label-app-list-header">Configure Quick Links</span>
                    </div>
                    <div
                        v-if="!showLoader && !isFormEditScreen && this.subModulesList.length < 2"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <button
                            type="button"
                            class="no-bg text-button btn-add-new-app"
                            style="margin-right: 2rem"
                            @click="isFormEditScreen = true"
                        >
                            Add New Link
                        </button>
                    </div>
                </div>
            </div>

            <div class="modal-list-third-party-app">
                <template v-if="isFormEditScreen">
                    <ValidationObserver ref="form">
                        <form class="p-0 shadow-none">
                            <div class="section-body add-new-app-form mx-0">
                                <div
                                    class="app-img-upload block field-container d-flex align-items-center mb-2 mx-5 px-3"
                                >
                                    <label class="me-3 d-flex justify-content-end add-new-app-label"
                                        >Icon/Images*</label
                                    >

                                    <div class="d-flex align-items-center w-100 gap-1">
                                        <button
                                            type="button"
                                            class="w-70 no-bg text-button btn-add-new-app"
                                            @click="chooseFile"
                                        >
                                            Choose file
                                        </button>
                                        <input
                                            id="fileid"
                                            type="file"
                                            name="upload"
                                            ref="upload"
                                            size="100000"
                                            @change="uploadFile"
                                            hidden
                                            required
                                            accept=".png, .jpg, .jpeg"
                                        />

                                        <div
                                            class="img-container-small d-flex justify-content-center align-items-center"
                                        >
                                            <img
                                                v-if="!uploadedImg && !showImgLoader"
                                                src="@/assets/px/drag-and-drop/dummyUploadImage.svg"
                                            />

                                            <img
                                                v-if="uploadedImg && !showImgLoader"
                                                :src="uploadedImg"
                                                alt="image not uploaded"
                                            />

                                            <div class="d-flex flex-column mt-3" v-if="showImgLoader">
                                                <div
                                                    class="d-flex justify-content-center align-items-center img-loader-main"
                                                >
                                                    <b-spinner variant="primary" class="img-loader"></b-spinner>
                                                </div>
                                                <span class="img-loader-text">Uploading...</span>
                                            </div>
                                            <ejs-tooltip
                                                class="tooltipcontainer"
                                                content="Delete"
                                                target="#target"
                                                cssClass="customtooltip"
                                                position="top"
                                            >
                                                <span
                                                    id="target"
                                                    class="material-symbols-outlined img-delete-small"
                                                    role="button"
                                                    @click="resetFileUpload()"
                                                >
                                                    delete
                                                </span>
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div class="mx-5 px-3">
                                    <Input
                                        label="Title"
                                        labelClass="add-new-app-label flex me-3 justify-content-end w-30"
                                        type="text"
                                        class="field-container d-flex align-items-center mb-4 w-80"
                                        name="title"
                                        placeholder="Enter Title"
                                        v-model="functionInfo.text"
                                        required
                                    />
                                    <Input
                                        label="URL"
                                        labelClass="add-new-app-label flex me-3 justify-content-end w-30"
                                        type="text"
                                        class="field-container d-flex align-items-center mb-4 w-80"
                                        name="url"
                                        placeholder="Enter URL"
                                        v-model="functionInfo.link"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="section-footer add-new-app-form my-4">
                                <div class="d-flex justify-content-center">
                                    <button
                                        type="button"
                                        class="primary btn-save-application"
                                        :disabled="showImgLoader"
                                        @click="saveQuickLink"
                                    >
                                        Save
                                    </button>
                                    <button type="button" class="no-bg text-button btn-cancel px-4" @click="resetForm">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ValidationObserver>
                </template>

                <div v-if="!isFormEditScreen" class="section-body content-checkbox-list">
                    <template v-if="subModulesList.length > 0">
                        <div
                            v-for="subModule in subModulesList"
                            :key="subModule.uuid"
                            class="d-flex justify-content-between align-items-center"
                        >
                            <span class="label-app-list-header w-75">{{ subModule.text }}</span>
                            <ejs-tooltip
                                class="tooltipcontainer"
                                content="Edit"
                                target="#target"
                                cssClass="customtooltip"
                                position="BottomRight"
                            >
                                <span
                                    id="target"
                                    class="material-symbols-outlined icon-edit me-2"
                                    role="button"
                                    @click="editSubModule(subModule.uuid)"
                                >
                                    edit_square
                                </span>
                            </ejs-tooltip>
                        </div>
                        <hr />
                    </template>
                    <div class="my-4 text-center" style="color: gray; font-weight: 600">
                        Note: You Have to Add 2 Links to use Quick Links Section in Dashboard
                    </div>
                    <div class="section-footer add-new-app-form my-4">
                        <div class="d-flex justify-content-center">
                            <button
                                type="button"
                                class="primary btn-save-application"
                                :disabled="showLoader || subModulesList.length < 2"
                                @click="save"
                            >
                                Save
                            </button>
                            <button type="button" class="no-bg text-button btn-cancel px-4" @click="$emit('close')">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showLoader" class="d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import { ValidationObserver } from 'vee-validate';
    import { memberProfile, dashboardBuilder } from '@/util/apiRequests';

    const initialFunctionInfoState = {
        text: '',
        image: '',
        link: '',
    };

    export default {
        name: 'ConfigureQuickLinksModal',
        components: { NoButtonModal, ValidationObserver, SpinLoader, draggable },
        props: {
            open: {
                type: Boolean,
                required: true,
            },
            subModules: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                showLoader: true,
                showImgLoader: false,
                subModulesList: [],
                functionInfo: { ...initialFunctionInfoState },
                uploadedImg: '',
                isFormEditScreen: false,
            };
        },
        methods: {
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.functionInfo.image = '';
            },
            resetForm() {
                this.isFormEditScreen = false;
                this.functionInfo = { ...initialFunctionInfoState };
                this.resetFileUpload();
            },
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    // Check File size should be less than 10 MB
                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    //HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const readerResult = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = (event) => resolve(event.target.result);
                            reader.onerror = (error) => reject(error);
                            reader.readAsDataURL(file);
                        });

                        this.uploadedImg = readerResult;

                        const formData = new FormData();
                        formData.append('file', file);
                        const endpoint = memberProfile.uploadProfileFunctionsImage();
                        const uploadImgResponse = await this.$api.post(endpoint, formData);
                        if (!uploadImgResponse.data.success) {
                            throw uploadImgResponse.data.error;
                        }
                        this.functionInfo.image = uploadImgResponse.data.s3_link;
                        this.$toasted.success('Image uploaded successfully!');
                    } catch (e) {
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                        this.$refs.upload.value = '';
                    } finally {
                        this.showImgLoader = false;
                    }
                }
            },
            async saveQuickLink() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }

                if (!this.functionInfo.image) {
                    this.$toasted.error('Please upload module image');
                    return;
                }

                if (this.functionInfo.uuid) {
                    this.subModulesList = this.subModulesList.map((subModule) => {
                        if (subModule.uuid === this.functionInfo.uuid) {
                            return { ...this.functionInfo };
                        }
                        return subModule;
                    });
                } else {
                    this.subModulesList.push({ uuid: this.subModulesList.length + 1, ...this.functionInfo });
                }

                this.resetForm();
                this.$toasted.success('Link saved successfully!');
            },
            async editSubModule(uuid) {
                this.isFormEditScreen = true;
                this.functionInfo = this.subModulesList.find((subModule) => subModule.uuid === uuid);
                this.uploadedImg = this.functionInfo.imageUrl ?? (await this.geturl(this.functionInfo.image));
            },
            save() {
                this.subModulesList = this.subModulesList.map((submodule) => {
                    delete submodule.uuid;
                    return submodule;
                });
                this.$emit('save', [...this.subModulesList]);
            },
        },
        async created() {
            this.subModulesList = this.subModules.map((submodule, index) => {
                submodule.uuid = index + 1;
                return submodule;
            });
            this.showLoader = false;
        },
    };
</script>

<style scoped>
    .loader-main {
        min-height: 50vh;
    }

    .add-list-function-loader {
        height: 2em;
        width: 2em;
        font-weight: bolder;
    }

    .img-loader-main {
        min-height: 30px;
    }

    .img-loader {
        height: 0.9em;
        width: 0.9em;
        font-weight: 600;
    }

    .img-loader-text {
        font-size: 0.8em;
        font-weight: 600;
        color: var(--base-theme-color);
    }

    .img-container {
        padding: 5px;
        border-radius: 10px;
        max-height: 50px;
        cursor: pointer;
        box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.1);
    }

    .label-app-list-header {
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
        text-transform: capitalize;
    }
</style>

<style>
    .configure-quick-links .modal__content {
        padding: 0;
        border-radius: 10px;
    }
</style>
