<template>
    <div class="rounded-5 w-100 my-5">
        <div class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Build Dashboard
            </h1>
            <div class="m-4 d-flex flex-column gap-2">
                <div v-for="section in sections" :key="section.id">
                    <label class="app-launcher-label">{{ section.label }}</label>
                    <div class="d-flex flex-wrap py-4" style="gap: 0.5em">
                        <ClinicianProfileFunctionCard
                            v-for="item in section.modules"
                            :key="item.uuid"
                            :profileFunction="item"
                            @openConfigureQuickLinkModalOpen="isConfigureQuickLinkModalOpen = true"
                            @onToggle="(event) => handleToggle(event, section.id)"
                        />
                        <button
                            v-if="section.id === 'left' && isQuickLinkActive"
                            class="save-btn"
                            @click="isConfigureQuickLinkModalOpen = true"
                        >
                            Configure Quick Links
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ConfigureQuickLinksModal
            v-if="isConfigureQuickLinkModalOpen"
            :open="isConfigureQuickLinkModalOpen"
            :subModules="subModuleList"
            @save="saveQuickLinks"
            @close="isConfigureQuickLinkModalOpen = false"
        />
        <Loading v-if="loading" />
    </div>
</template>

<script>
    import ClinicianProfileFunctionCard from '@/components/company/member-profiles/ClinicianProfileFunctionCard.vue';
    import ConfigureQuickLinksModal from './ConfigureQuickLinksModal.vue';
    import Loading from '@/components/general/loading/loading.vue';

    export default {
        name: 'BuildClinicianDashboard',
        components: { ClinicianProfileFunctionCard, ConfigureQuickLinksModal, Loading },
        props: {
            modules: {
                type: Array,
                default: () => [],
            },
            genericModules: {
                type: Array,
                default: () => [],
            },
            subModules: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            isQuickLinkActive() {
                return this.genericModuleList.some((module) => module.function_name === 'Quick Links');
            },
        },
        data() {
            return {
                loading: false,
                isConfigureQuickLinkModalOpen: false,
                genericModulesErr: null,
                genericModuleList: [],
                subModuleList: [],
                sections: [
                    { id: 'left', label: 'Left Section', modules: [] },
                    { id: 'center', label: 'Center Section', modules: [] },
                    { id: 'right', label: 'Right Section', modules: [] },
                ],
            };
        },
        methods: {
            handleToggle(event, sectionId) {
                const section = this.sections.find((s) => s.id === sectionId);
                if (!section) return;

                // Disable the module in other sections before enabling it in the selected section
                this.sections.forEach((s) => {
                    s.modules.forEach((m) => {
                        if (m.profile_function_id === event.profileFunction.profile_function_id) {
                            m.active = false; // Disable in other sections
                        }
                    });
                });

                // Enable the module in the selected section
                section.modules = section.modules.map((module) => ({
                    ...module,
                    active: module.uuid === event.profileFunction.uuid ? event.value : false,
                }));

                // Collect active modules
                this.genericModuleList = this.sections.flatMap((s) =>
                    s.modules
                        .filter((m) => m.active)
                        .map((m) => ({
                            ...m,
                            section: s.id,
                        }))
                );

                if (event.profileFunction.function_name === 'Quick Links' && event.value) {
                    this.isConfigureQuickLinkModalOpen = true;
                } else if (sectionId === 'left') {
                    this.subModuleList = [];
                    this.$emit('captureValues', { subModules: this.subModuleList });
                }

                this.genericModulesErr = null;

                this.$emit('captureValues', { genericModules: this.genericModuleList });
            },
            initializeModules() {
                this.genericModuleList = [...this.genericModules];
                this.subModuleList = [...this.subModules];

                const genericModuleMap = new Map(
                    this.genericModuleList.map((el) => [`${el.function_name}_${el.section}`, el])
                );

                this.sections = this.sections.map((section) => {
                    const filteredModules = this.modules
                        .filter((m) => m.active && m.section.includes(section.id))
                        .map((m) => {
                            const key = `${m.function_name}_${section.id}`;
                            const genericModule = genericModuleMap.get(key);

                            return {
                                id: genericModule?.id,
                                profile_function_id: m.id,
                                function_name: m.function_name,
                                active: Boolean(genericModule),
                                uuid: `${section.id}_${m.id}`,
                            };
                        });

                    return { ...section, modules: filteredModules };
                });

                // Collect active modules
                this.genericModuleList = this.sections.flatMap((s) =>
                    s.modules
                        .filter((m) => m.active)
                        .map((m) => ({
                            ...m,
                            section: s.id,
                        }))
                );

                this.$emit('captureValues', { genericModules: this.genericModuleList });
            },
            saveQuickLinks(subModules) {
                this.isConfigureQuickLinkModalOpen = false;
                this.subModuleList = subModules;
                this.$emit('captureValues', { subModules: this.subModuleList });
            },
        },
        created() {
            this.initializeModules();
        },
    };
</script>

<style scoped>
    .save-btn {
        background: #5057c3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }
</style>
