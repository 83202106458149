<template>
    <div class="profile-function-card d-flex justify-content-between align-items-center">
        <span class="profile-function-card-label me-3">{{ profileFunction.function_name }}</span>
        <Toggle
            :id="profileFunction.uuid"
            class="toggle-switch"
            labelClass="switch"
            spanClass="slider"
            :value="profileFunction.active"
            @input="(value) => handleToggle(value, profileFunction)"
        />
    </div>
</template>

<script>
    export default {
        name: 'ClinicianProfileFunctionCard',
        props: {
            profileFunction: {
                type: Object,
                required: true,
            },
        },
        methods: {
            handleToggle(value, profileFunction) {
                this.$emit('onToggle', { value, profileFunction });
            },
        },
    };
</script>
