<template>
    <div>
        <div class="d-flex justify-content-center align-items-center py-3">
            <div class="w-75 position-absolute" style="background: lightgray">
                <div class="position-absolute w-100" style="height: 3px; background: #e3e3e3"></div>
                <div class="position-absolute" style="height: 3px; background: green" v-bind:class="progress"></div>
            </div>
            <div class="d-flex justify-content-between align-items-center" style="width: 78%">
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(1)">
                    <span class="material-symbols-outlined icon" v-if="current_step > 1"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 1">
                        fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(2)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 2"> </span>
                    <span class="material-symbols-outlined icon" v-if="current_step > 2"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 2">
                        fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(3)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 3"> </span>
                    <span class="material-symbols-outlined icon" v-if="current_step > 3"> check_small </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 3">
                        fiber_manual_record
                    </span>
                </div>
                <div class="d-flex align-items-center justify-content-center icon-div" v-bind:class="getStatusClass(4)">
                    <span class="material-symbols-outlined icon" v-if="current_step < 4"> </span>
                    <span class="material-symbols-outlined icon current-icon" v-if="current_step === 4">
                        fiber_manual_record
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-between align-items-center w-100">
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Dashboard Details</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Select Modules</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Customize Theme</h6>
                </div>
                <div class="w-25 d-flex justify-content-center">
                    <h6 style="color: #a0a0a0">Build Dashboard</h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProgressBar',
        props: {
            current_step: {
                type: Number,
            },
        },
        computed: {
            progress: function () {
                let _class = '';
                if (this.current_step === 1) {
                    _class = '';
                } else if (this.current_step === 2) {
                    _class = 'w-33';
                } else if (this.current_step === 3) {
                    _class = 'w-67';
                } else if (this.current_step === 4) {
                    _class = 'w-100';
                }
                return _class;
            },
        },
        methods: {
            getStatusClass(section) {
                let _class = '';
                if (section === this.current_step) {
                    _class = 'current';
                } else if (section < this.current_step) {
                    _class = 'completed';
                } else if (section > this.current_step) {
                    _class = 'upcoming';
                }
                return _class;
            },
        },
    };
</script>

<style scoped>
    .icon-div {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        z-index: 99;
    }

    .icon {
        font-size: 35px;
        font-weight: 800;
        border-radius: 50%;
    }

    .completed {
        border: 5px solid #5657be;
        background: #5657be;
        color: white;
    }

    .current {
        border: 5px solid #5657be;
        background: white;
        color: #5657be;
        border-radius: 50%;
    }

    .current-icon {
        border-radius: 50%;
        background: #5657be;
        font-size: 20px;
    }

    .upcoming {
        border: 5px solid lightgray;
        background: white;
    }
</style>
