<template>
    <div class="bg-white w-100 rounded-4">
        <h1 class="px-4 mt-4">Enter Dashboard Name*</h1>
        <Input
            type="text"
            name="Dashboard Name"
            class="field-container px-4 mb-4"
            style="max-width: 30rem"
            input_style="text-transform: capitalize;"
            v-model="dashboard_name"
            inputClass="input-add-new-client"
            labelClass="class-label"
            @input="handleDashboardName"
            :required="true"
        />
        <span v-if="dashboardNameErr" class="px-4 my-4" style="color: red; font-weight: bold">{{
            dashboardNameErr
        }}</span>
    </div>
</template>

<script>
    export default {
        name: 'DashboardName',
        data() {
            return {
                dashboard_name: this.dashboardName,
                dashboardNameErr: null,
            };
        },
        props: {
            dashboardName: {
                type: String,
                required: true,
            },
            pageErrors: {
                type: Object,
                default: () => ({}),
            },
        },
        methods: {
            handleDashboardName() {
                this.dashboardNameErr = null;
                this.$emit('captureValues', { dashboardName: this.dashboard_name });
            },
        },
        watch: {
            pageErrors: {
                deep: true,
                async handler(val) {
                    if (val.dashboardName) {
                        this.dashboardNameErr = val.dashboardName;
                    }
                },
            },
        },
    };
</script>
