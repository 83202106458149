<template>
    <div class="rounded-5 w-100 my-5">
        <div class="bg-white w-100 rounded-4 border">
            <h1
                class="px-5 py-3 page-heading"
                style="background: #f1f7ff; font-weight: bold; border-radius: 14px 14px 0 0"
            >
                Theme Customization
            </h1>
            <div class="p-4">
                <div class="w-100 mx-3">
                    <h3 style="font-weight: 700">Color Scheme*</h3>
                </div>
                <div class="d-grid color-box" style="margin: 1.2rem 0rem">
                    <div class="m-4">
                        <label class="flex py-2 gap-2" for="customized_color">
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="main_background_color"
                                    v-model="color_scheme.provider_main_background_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">Main Background Color</div>
                            </div>
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="background_color"
                                    v-model="color_scheme.provider_background_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">Background Color</div>
                            </div>
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="text_color"
                                    v-model="color_scheme.provider_text_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">Text Color</div>
                            </div>
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="header_background_color"
                                    v-model="color_scheme.provider_header_background_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">
                                    Header Background Color
                                </div>
                            </div>
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="header_text_color"
                                    v-model="color_scheme.provider_header_text_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">Header Text Color</div>
                            </div>
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="navigation_background_color"
                                    v-model="color_scheme.provider_navigation_background_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">
                                    Navigation Background Color
                                </div>
                            </div>
                            <div class="d-flex align-items-center w-25 flex-column text-center gap-2">
                                <input
                                    type="color"
                                    name="navigation_text_color"
                                    v-model="color_scheme.provider_navigation_text_color"
                                    @input="handleColorScheme"
                                    class="w-100"
                                />
                                <div style="font-weight: 600; color: #000; font-size: 12px">Navigation Text Color</div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <Loading v-if="loading" />
    </div>
</template>

<script>
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import Loading from '@/components/general/loading/loading.vue';

    export default {
        name: 'CustomisedThemeDesign',
        props: {
            colorScheme: {
                type: Object,
                required: true,
            },
            pageErrors: {
                type: Object,
                default: () => ({}),
            },
        },
        components: {
            SpinLoader,
            Loading,
        },
        data() {
            return {
                loading: false,
                colorSchemeErr: null,
                color_scheme: {},
            };
        },
        methods: {
            handleColorScheme() {
                this.$emit('captureValues', { colorScheme: this.color_scheme });
            },
        },
        created() {
            this.color_scheme = { ...this.colorScheme };
        },
        watch: {
            pageErrors: {
                deep: true,
                async handler(val) {
                    if (val.color_scheme) {
                        this.colorSchemeErr = val.colorScheme;
                    }
                },
            },
        },
    };
</script>

<style scoped>
    .color-box {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.12);
    }
</style>
