<template>
    <div class="bg-white rounded-5">
        <ProgressBar :current_step="current_step" />
        <div class="px-5 mx-2">
            <div class="d-flex justify-content-start" v-if="!loading">
                <DashboardName
                    v-if="current_step === 1"
                    @captureValues="captureValues"
                    :dashboardName="dashboardName"
                    :pageErrors="pageErrors"
                />
                <SelectModules
                    v-if="current_step === 2"
                    @captureValues="captureValues"
                    :modules="modules"
                    :navigationEnabled="navigationEnabled"
                    :pageErrors="pageErrors"
                />
                <CustomisedThemeDesign
                    v-if="current_step === 3"
                    @captureValues="captureValues"
                    :colorScheme="colorScheme"
                    :pageErrors="pageErrors"
                />
                <BuildClinicianDashboard
                    v-if="current_step === 4"
                    @captureValues="captureValues"
                    :modules="modules"
                    :genericModules="genericModules"
                    :subModules="subModules"
                />
            </div>
        </div>

        <div class="mx-5">
            <div class="py-5 d-flex justify-content-end">
                <button class="other-btn" @click="onClickBack" v-if="current_step > 1">Back to Previous</button>
                <button v-if="current_step < max_step" class="save-btn" @click="onClickNext">Next</button>
                <button v-if="current_step == max_step" class="save-btn" @click="completeDashboard">Save</button>
            </div>
        </div>

        <Loading v-if="loading" />
    </div>
</template>

<script>
    import Loading from '@/components/general/loading/loading.vue';
    import ProgressBar from './progress_bar.vue';
    import DashboardName from './dashboard_name.vue';
    import SelectModules from './select_modules.vue';
    import CustomisedThemeDesign from './customised_theme_design.vue';
    import BuildClinicianDashboard from './build_clinician_dashboard.vue';
    import { clinicianDashboardBuilder, clinicianModules, cardColors } from '@/util/apiRequests';

    const initialErrors = {
        dashboardName: null,
        modules: null,
        colorScheme: null,
        genericModules: null,
    };

    const initialColorScheme = {
        main_background_color: '#FF6600',
        background_color: '#ffffff',
        text_color: '#000000',
        header_background_color: '#329DE6',
        header_text_color: '#ffffff',
        navigation_background_color: '#000000',
        navigation_text_color: '#ffffff',
    };

    export default {
        name: 'ClinicianDashboardBuilder',
        components: {
            ProgressBar,
            DashboardName,
            SelectModules,
            CustomisedThemeDesign,
            BuildClinicianDashboard,
            Loading,
        },
        data() {
            return {
                current_step: 1,
                max_step: 4,
                loading: true,
                dashboardId: null,
                dashboardName: '',
                navigationEnabled: true,
                colorScheme: { ...initialColorScheme },
                modules: [],
                selectedModules: [],
                genericModules: [],
                selectedGenericModules: [],
                subModules: [],
                isNextButtonProcessing: false,
                pageErrors: { ...initialErrors },
            };
        },
        methods: {
            async fetchDashboardData() {
                try {
                    const response = await this.$api.get(clinicianDashboardBuilder.getTemplate(this.dashboardId));
                    if (response.data.templateMeta) {
                        this.dashboardName = response.data.templateMeta.dashboardName;
                        this.navigationEnabled = response.data.templateMeta.navigation_enabled ? true : false;
                        this.colorScheme = JSON.parse(response.data.templateMeta.color_scheme);
                    }
                    return true;
                } catch (error) {
                    this.$toasted.error('Error fetching dashboard data:', error);
                    throw new Error(error);
                }
            },
            async getDefaultProfileFunctions() {
                try {
                    const endpoint = clinicianModules.getDefaultProfileFunctions();
                    const fetchFunctionResponse = await this.$api.get(endpoint);

                    const data = fetchFunctionResponse?.data?.map((el) => {
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });

                    this.modules = data;
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch Modules';
                    this.$toasted.error(errorMesage);
                }
            },
            async getProfileFunctionsByDashboardId() {
                try {
                    const endpoint = clinicianModules.getProfileFunctionsByDashboardId(this.dashboardId);
                    const fetchFunctionResponse = await this.$api.get(endpoint);

                    const data = fetchFunctionResponse?.data?.map((el) => {
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });

                    this.selectedModules = data;

                    this.modules = this.modules.map((el) => {
                        let val = data.find((el1) => el1.function_name === el.function_name);
                        if (val) {
                            val.active = true;
                            return val;
                        }
                        el.active = false;
                        return el;
                    });
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch Modules';
                    this.$toasted.error(errorMesage);
                }
            },
            async getGenericModulesByDashboardId() {
                try {
                    const endpoint = clinicianModules.getGenericModulesByDashboardId(this.dashboardId);
                    const fetchFunctionResponse = await this.$api.get(endpoint);

                    const data = fetchFunctionResponse?.data?.map((el) => {
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });

                    this.genericModules = data;
                    this.selectedGenericModules = data;

                    const isQuickLinks = data.find((el) => el.function_name === 'Quick Links');
                    if (isQuickLinks) {
                        await this.getSubModules(isQuickLinks.id);
                    }
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch Modules';
                    this.$toasted.error(errorMesage);
                }
            },
            async getSubModules(id) {
                try {
                    const endpoint = clinicianModules.getSubModulesById(id);
                    const fetchFunctionResponse = await this.$api.get(endpoint);

                    const data = fetchFunctionResponse?.data?.map((el) => {
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted;
                        return el;
                    });

                    this.subModules = data;
                } catch (e) {
                    const errorMesage = e ? e : 'Failed to fetch Modules';
                    this.$toasted.error(errorMesage);
                }
            },
            async getCompanyColors() {
                try {
                    const endpoint = cardColors.getCardColor(this.$store.state.user?.company_id);
                    const response = await this.$api.get(endpoint);

                    this.colorScheme = response?.data?.message[0];
                } catch (err) {
                    this.$toasted.error('Failed to fetch Protal Colors');
                }
            },
            async checkDashboardNameExists() {
                try {
                    this.loading = true;
                    const endpoint = clinicianDashboardBuilder.checkDashboardName();
                    const res = await this.$api.post(endpoint, {
                        dashboardName: this.dashboardName,
                        id: this.dashboardId,
                    });
                    if (!res.data.success) {
                        this.$toasted.error(res.data.error);
                        this.pageErrors.dashboardName = res.data.error;
                        return true;
                    }
                } catch (err) {
                    this.pageErrors.dashboardName = 'Failed to check dashboard name';
                    return true;
                } finally {
                    this.loading = false;
                }
                return false;
            },
            async onClickNext() {
                if (this.isNextButtonProcessing) {
                    return;
                }

                try {
                    this.isNextButtonProcessing = true;
                    this.pageErrors = { ...initialErrors };

                    if (this.current_step === 1) {
                        if (!this.dashboardName) {
                            this.pageErrors.dashboardName = 'Please enter Dashboard Name';
                            return;
                        }

                        const checkDashboardNameExists = await this.checkDashboardNameExists();
                        if (checkDashboardNameExists) {
                            return;
                        }
                    } else if (this.current_step === 2) {
                        const modules = this.modules.filter((el) => el.active);
                        if (!modules.length) {
                            this.pageErrors.modules = 'Please select at least one Module';
                            return;
                        }
                    }

                    if (this.current_step < this.max_step) {
                        window.scrollTo(0, 0);
                        this.current_step++;
                    }
                } catch (error) {
                    console.error('Error in onClickNext:', error);
                } finally {
                    this.isNextButtonProcessing = false;
                }
            },
            onClickBack() {
                window.scrollTo(0, 0);
                this.current_step > 1 ? this.current_step-- : this.current_step;
            },
            async captureValues(event) {
                const { dashboardName, modules, navigationEnabled, colorScheme, genericModules, subModules } = event;

                if (dashboardName !== undefined) {
                    this.dashboardName = dashboardName;
                }
                if (navigationEnabled !== undefined) {
                    this.navigationEnabled = navigationEnabled;
                }
                if (modules !== undefined) {
                    this.modules = [...modules];
                }
                if (colorScheme !== undefined) {
                    this.colorScheme = { ...colorScheme };
                }
                if (genericModules !== undefined) {
                    this.genericModules = [...genericModules];
                }
                if (subModules !== undefined) {
                    this.subModules = [...subModules];
                }
            },
            async completeDashboard() {
                if (!this.validateInputs()) {
                    return;
                }

                this.loading = true;

                try {
                    // Prepare data
                    const payload = this.preparePayload();

                    // Handle API operations
                    await this.handleApiOperations(payload);

                    // Success handling
                    this.$router.push({ name: 'ClinicianDashboardBuilderList' });
                    this.$toasted.success(`Dashboard ${this.dashboardId ? 'Updated' : 'Created'} Successfully`);
                } catch (error) {
                    this.$toasted.error(`Error completing dashboard: ${error.message}`);
                    console.error('Error in completeDashboard:', error);
                } finally {
                    this.loading = false;
                }
            },
            validateInputs() {
                if (this.genericModules.length < 3) {
                    this.$toasted.error('Please select Module for each Section');
                    return false;
                }

                const isQuickLinkActive = this.genericModules.find((el) => el.function_name === 'Quick Links');
                if (isQuickLinkActive && this.subModules.length < 2) {
                    this.$toasted.error('Please Configure Quick Links');
                    return false;
                }

                return true;
            },
            preparePayload() {
                const profileModules = this.modules
                    .filter((module) => module.active)
                    .map(
                        (module) =>
                            this.selectedModules.find((m) => m.function_name === module.function_name) || {
                                function_name: module.function_name,
                                section: module.section,
                            }
                    );

                const modules = this.genericModules.map(({ id, profile_function_id, section, function_name }) => {
                    const moduleData = {
                        id,
                        position: 1,
                        section,
                        profile_function_id,
                    };

                    if (function_name === 'Quick Links') {
                        moduleData.submodules = this.subModules.map(({ id, text, image, link }) => ({
                            id,
                            text,
                            image,
                            link,
                        }));
                    }

                    return id ? { ...moduleData, profile_function_id: undefined } : moduleData;
                });

                return {
                    status: 'completed',
                    dashboardName: this.dashboardName,
                    navigation_enabled: this.navigationEnabled,
                    profile_modules: profileModules,
                    modules,
                    color_scheme: this.colorScheme,
                };
            },
            async handleApiOperations(payload) {
                // Handle dashboard creation/update
                const endpoint = this.dashboardId
                    ? clinicianDashboardBuilder.updateTemplate(this.dashboardId)
                    : clinicianDashboardBuilder.createTemplate();
                const method = this.dashboardId ? 'put' : 'post';

                const response = await this.$api[method](endpoint, payload);
                if (!response.data.success) {
                    throw new Error(response.data.error);
                }

                // Clean up unused modules
                const unusedModules = this.getUnusedModules(payload.profile_modules, payload.modules);
                await this.deleteUnusedModules(unusedModules);
            },
            getUnusedModules(profileModules, modules) {
                return {
                    profileFunctionIds: this.selectedModules
                        .filter((m) => !profileModules.some((pm) => pm.id === m.id))
                        .map((m) => m.id),
                    genericModuleIds: this.selectedGenericModules
                        .filter((m) => !modules.some((pm) => pm.id === m.id))
                        .map((m) => m.id),
                };
            },
            async deleteUnusedModules({ profileFunctionIds, genericModuleIds }) {
                const deletionPromises = [
                    ...profileFunctionIds.map((id) => this.$api.delete(clinicianModules.deleteProfileFunction(id))),
                    ...genericModuleIds.map((id) => this.$api.delete(clinicianModules.deleteGenericModule(id))),
                ];

                await Promise.all(deletionPromises);
            },
        },
        async created() {
            window.scrollTo(0, 0);

            await this.getDefaultProfileFunctions();

            if (this.$route.query.id) {
                this.dashboardId = Number(this.$route.query.id);
                await this.fetchDashboardData();
                await this.getProfileFunctionsByDashboardId();
                await this.getGenericModulesByDashboardId();
            } else {
                await this.getCompanyColors();
            }

            this.loading = false;
        },
    };
</script>

<style scoped>
    .other-btn {
        background: #fff;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: #5057c3;
        width: 200px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid 5057C3;
        font-weight: 500;
    }

    .save-btn {
        background: #5057c3;
        box-shadow: 0px 8px 22px 6px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
        color: white;
        width: 150px;
        height: 50px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 5px;
    }
</style>
