import { render, staticRenderFns } from "./ConfigureQuickLinksModal.vue?vue&type=template&id=768fb3cf&scoped=true&"
import script from "./ConfigureQuickLinksModal.vue?vue&type=script&lang=js&"
export * from "./ConfigureQuickLinksModal.vue?vue&type=script&lang=js&"
import style0 from "./ConfigureQuickLinksModal.vue?vue&type=style&index=0&id=768fb3cf&prod&scoped=true&lang=css&"
import style1 from "./ConfigureQuickLinksModal.vue?vue&type=style&index=1&id=768fb3cf&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768fb3cf",
  null
  
)

export default component.exports